<template>
  <div class="container" v-if="dataReady">
    <AssignmentbarIntroduction
      @end-assignment="alert('endAssignment')"
    ></AssignmentbarIntroduction>
    <div class="row animate glow delay-1">
      <h1>Über Informatikstudium an der TU Wien</h1>
    
      <p>
        Die
        <a href="http://www.informatik.tuwien.ac.at/" target="_blank"
          >Fakultät für Informatik</a
        >
        ist die größte Forschungs- und Ausbildungsstätte im Bereich Informatik und Wirtschaftsinformatik in Österreich. 
        Die Informatik ist ein schnelllebiges Gebiet, das vielfältige Disziplinen zusammenbringt. 
        Unsere Bachelorstudien vermitteln Ihnen Fähigkeiten, die in der Industrie hochgeschätzt werden. 
        Gleichzeitig erhalten Sie die notwendige Basis für ein weiterführendes Masterstudium und für eine Laufbahn in der Forschung.
      </p>
      <p>
        Das Motto der
        <a href="https://www.tuwien.ac.at" target="_blank">TU Wien</a> ist
        <a href="https://www.youtube.com/watch?v=n7awVUYcZJg" target="_blank"
          >„Technik für Menschen“
          <img :src="require('@/assets/inf/database/Youtube-Logo.png')" alt="YouTube" style="width:40px;height:30px;"></a
        >. An unserer Universität wird
        <a href="https://informatics.tuwien.ac.at/foci" target="_blank"
          >forschungsgeleitet</a
        >
        gelehrt. Das bedeutet, was wir lehren, forschen wir auch selber. Als Lehrende der TU Wien sind wir auf dem neuesten Wissensstand und das bringen wir auch Ihnen bei.
      </p>
    </div>

      <div class="yt-video animate glow video-informatik-teaser">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/n7awVUYcZJg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>  
      </div>

      <div class="row animate glow delay-1">
      <p>
        Unsere Bachelorstudien und alle Details zum Qualifikationsprofil der Studien, zu den Berufsbildern und -aussichten, bzw. Zulassungsbedingungen finden Sie unter
        <a
          href="https://www.tuwien.at/studium/studienangebot/bachelorstudien/informatik-und-wirtschaftsinformatik"
          target="_blank"
          >Bachelorstudien Informatik und Wirtschaftsinformatik</a
        >.
      </p>
      </div>

      <div class="yt-video animate glow video-tuwien">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/9FwJ-IBeGGs"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <div class="row animate glow delay-1">
      <p>
        Für Eindrücke und Stimmen aus der TU Wien, besuchen Sie uns in
        <a href="https://www.youtube.com/watch?v=9FwJ-IBeGGs" target="_blank"
          >YouTube</a
        >. Neben den Lehrveranstaltungen finden an der
        <a href="https://www.youtube.com/watch?v=szBSjD_yf4I" target="_blank"
          >Fakultät für Informatik</a
        >
        viele interessante
        <a
          href="https://www.youtube.com/playlist?list=PLQku6m__XAxGeq3G0o7oiW_hyzWBN1-UZ"
          target="_blank"
          >Veranstaltungen</a
        >
        statt.
      </p>
      <!-- <p>Noch was Interessantes direkt von unseren Studierenden für Sie:</p> -->
    </div>
    <div class="btn-container">
      <div class="custom-btn" @click="finishAboutTU()">Weiter</div>
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarIntroduction from "@/components/inf/introduction/AssignmentbarIntroduction.vue";
import * as scrollHelper from "@/helpers/scroll-helper.js";

export default {
  name: "InformatikIntroduction",
  components: {
    AssignmentbarIntroduction,
  },
  emits: ["about-tu-finished", "about-tu-back"],
  mounted() {
    scrollHelper.scrollToTop();
    this.dataReady = true;
  },
  data() {
    return {
      dataReady: false,
    };
  },
  methods: {
    async finishAboutTU() {
      this.$emit("about-tu-finished");
    },
    backBtnClicked() {
      this.$emit("about-tu-back");
    },
  },
};
</script>

<style scoped>
.yt-video {
  display: flex;
  justify-content: center;
  animation-delay: 0.8s;
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.delay-1 {
  animation-delay: 0.2s;
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
