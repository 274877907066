<template>
  <div class="container" v-if="dataReady">
    <AssignmentbarIntroduction
      @end-assignment="alert('endAssignment')"
    ></AssignmentbarIntroduction>
    <div class="row animate glow delay-1">
      <h1>Über Informatik</h1>
      <div class="display-9" style="margin-bottom: 20px">
        „Informatik hat unser Leben verändert. Informatik ist überall.“ 
        <a href="https://www.youtube.com/watch?v=szBSjD_yf4I" target="_blank">
          <img :src="require('@/assets/inf/database/Youtube-Logo.png')" alt="YouTube" style="width:40px;height:30px;">
</a>
      </div>
      <p>
        Informatik bezeichnet die Wissenschaft, die sich mit der Darstellung, Speicherung, Verarbeitung und Übertragung von Daten und Informationen beschäftigt, hauptsächlich unter Einsatz von Computern. Der Begriff selbst ist eine Zusammensetzung aus den Worten „Information“ und „Automatik“. Die Informatik teilt sich in verschiedene Fachgebiete auf, darunter theoretische Informatik, praktische Informatik, technische Informatik und angewandte Informatik.

      </p>
      <p>
        Die <strong>theoretische Informatik</strong> konzentriert sich auf abstrakte Aspekte, die eng mit mathematischen Fragen verbunden sind, wie Automatentheorie, formale Sprachen, Berechenbarkeit und Komplexitätstheorie. 
        Ein wichtiges Konzept in diesem Bereich ist die Turing-Maschine, ein Modellrechner, der zur Untersuchung der Berechenbarkeit von Funktionen dient.

      </p>
    

     <p>Die <strong>praktische Informatik</strong> zielt darauf ab, Erkenntnisse aus der Informatik zur Lösung realer Probleme zu verwenden, etwa durch die Entwicklung von Programmiersprachen und Software. 
      Dies umfasst auch Themen wie Softwaretechnik, Betriebssysteme, Algorithmik und Datenstrukturen.
</p>

     <p>Die <strong>technische Informatik</strong> befasst sich mit dem Entwurf und der Realisierung von Rechnerarchitekturen sowie mit Hardware und systemnaher Software, und ist in ihren Grundlagen eng mit der Elektrotechnik verwoben.</p>

     <p>Die <strong>angewandte Informatik</strong> wiederum findet Anwendung in anderen Fachgebieten, wie der Biologie oder Medizin, und beinhaltet Teilgebiete wie Computergrafik, Simulationen und Datenverarbeitung.
</p>
<p>Diese Definition und Aufschlüsselung der Informatik verdeutlicht die Vielseitigkeit und Interdisziplinarität des Fachs, das fundamentale Konzepte und Methoden zur Problemlösung in der digitalen Welt bereitstellt.
</p>
</div>
    <div class="btn-container">
      <div class="custom-btn" @click="finishAboutTU()">Weiter</div>
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarIntroduction from "@/components/inf/introduction/AssignmentbarIntroduction.vue";
import * as scrollHelper from "@/helpers/scroll-helper.js";

export default {
  name: "InformatikIntroduction",
  components: {
    AssignmentbarIntroduction,
  },
  emits: ["about-tu-finished", "about-tu-back"],
  mounted() {
    this.$store.dispatch("setHeaderPageTitle", "Online Self Assessment Informatik");
    scrollHelper.scrollToTop();
    this.dataReady = true;
  },
  data() {
    return {
      dataReady: false,
    };
  },
  methods: {
    async finishAboutTU() {
      this.$emit("about-tu-finished");
    },
    backBtnClicked() {
      this.$emit("about-tu-back");
    },
  },
};
</script>

<style scoped>
.yt-video {
  display: flex;
  justify-content: center;
  animation-delay: 0.8s;
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.delay-1 {
  animation-delay: 0.2s;
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
