
<template>
  <div class="container" v-if="dataReady">
    <AssignmentbarIntroduction
      @end-assignment="alert('endAssignment')"
    ></AssignmentbarIntroduction>
    <div class="row animate glow delay-1">
      <h1>Über Informatik und Informatikstudium an der TU Wien</h1>
      <p>
        Die Informatik ist vielfältig. Sie ist eine Grundlagen-, Gestaltungswissenschaft und eine Ingenieursdisziplin zugleich. 
        Mit der Informatik werden gesellschaftliche und wirtschaftliche Entwicklungen vorangetrieben. Sie verändert unsere Gesellschaft nachhaltig. 
        Ohne Informatik kann man sich kaum Innovationen oder wissenschaftliche Durchbrüche vorstellen. Informatik ist immer und überall vorhanden.
      </p>
      <p>Informatik verarbeitet Informationen technisch. Dafür verwendet sie Algorithmen. Die Verarbeitung von Informationen umfasst vor allem das Speichern, Übertragen und Darstellen von Informationen.</p>
      <p>
        Informatik ist zukunftsweisend und essentiell für unsere Gesellschaft. 
        Wir brauchen Informatiker:innen, die nützliche und sinnvolle Technologien entwickeln und umsetzen, um mit dem rasanten Wandel unserer Gesellschaft Schritt zu halten und sogar ihn zu gestalten.
      </p>
      <p>
        Auf den kommenden Seiten möchten wir Ihnen Informationen zum Informatikstudium an der TU Wien und kurze Einblicke in verschiedene Inhalte der Informatik geben.
        Versuchen Sie beim Besuch des Moduls zu reflektieren, ob Sie sich näher mit solchen Problemstellungen im Rahmen eines Informatikstudiums beschäftigen wollen.
      </p>

    </div>
    <!-- <div class="row animate glow delay-2">
      <h2>Ihre Aufgaben in diesem Modul</h2>
      <p>
        Auf den kommenden Seiten möchten wir Ihnen Informationen zum
        Informatikstudium an der TU Wien und kurze Einblicke in verschiedene
        Inhalte der Informatik geben. Anhand von interaktiven Beispielen werden
        Sie verschiedene Themenbereiche der Informatik kurz kennen lernen.
        Versuchen Sie bei Bearbeitung des Moduls zu reflektieren, ob Sie sich
        näher mit solchen Problemstellungen im Rahmen eines Informatikstudiums
        beschäftigen wollen.
      </p>
      <p v-if="false">
        Im Gegensatz zu den Experimentiermodulen des OSA Informatik wird „Über
        Informatik“ nicht bewertet und Ihre Bearbeitung fließt nicht in das
        Endergebnis ein. Nehmen Sie ich für dieses Modul ca. 15-30 Minuten Zeit.
      </p>
    </div> -->
    <div class="btn-container">
      <div class="custom-btn" @click="finishIntroduction()">Weiter</div>
    </div>
  </div>
</template>

<script>

import AssignmentbarIntroduction from "@/components/inf/introduction/AssignmentbarIntroduction.vue";
import * as scrollHelper from "@/helpers/scroll-helper.js";

export default {
  name: "InformatikIntroduction",
  components: {
    AssignmentbarIntroduction,
    
  },
  emits: ["introduction-finished"],
  mounted() {
    
  this.$store.dispatch('setIsUserInAssignment', false);
    scrollHelper.scrollToTop();
    this.dataReady = true;
  },
  data() {
    return {
      dataReady: false,
    };
  },
  methods: {
    async finishIntroduction() {
      this.$emit("introduction-finished");
    },
  },
};
</script>

<style scoped>

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.delay-1 {
  animation-delay: 0.5s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

.custom-btn{
  float: right;
}

</style>
