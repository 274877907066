<template>
  <div class="container" v-if="dataReady">
    <AssignmentbarIntroduction
      @end-assignment="alert('endAssignment')"
    ></AssignmentbarIntroduction>
    <div class="row animate glow delay-1">
      <h1>Über Informatik</h1>
     
      <p>
        Neben den spezifischen Forschungsbereichen der Informatik gibt es zahlreiche Anwendungsbereiche, in denen informatische Konzepte und Technologien eine zentrale Rolle spielen. Diese Anwendungsbereiche demonstrieren die Vielseitigkeit der Informatik und ihre Bedeutung für praktisch alle Aspekte des modernen Lebens und verschiedener Industriezweige. Einige dieser Bereiche umfassen:

      </p>
      <p>
        <strong>Gesundheitswesen</strong> - Einsatz von Informationstechnologie zur Verbesserung der Patient:innenversorgung, Verwaltung von Gesundheitsdaten, 
        Telemedizin und Entwicklung von unterstützenden Technologien für Diagnoseverfahren und Behandlungspläne.
      </p>

      <p><strong>Finanztechnologie</strong> - Nutzung der Informatik zur Entwicklung von Technologien für das Banking und Finanzen, einschließlich mobiler Zahlungssysteme, automatisierter Anlageberatung und Blockchain-Technologien.</p>

      <p><strong>Smart Cities und urbane Informatik</strong>  - Anwendung informatischer Lösungen zur Verbesserung der Lebensqualität in städtischen Gebieten durch intelligente Verkehrssysteme, Energieverwaltung, öffentliche Sicherheit und nachhaltige Stadtentwicklung.</p>
      
      <p><strong>Bildungstechnologie</strong> - Entwicklung und Einsatz von Technologien zur Unterstützung des Lernens und der Lehre, einschließlich E-Learning-Plattformen, virtuellen Klassenzimmern und adaptiven Lernsystemen.</p>

      <p><strong>Umweltinformatik</strong> - Anwendung der Informatik zur Überwachung, Modellierung und Schutz der Umwelt, einschließlich der Analyse von Klimadaten, der Verwaltung natürlicher Ressourcen und der Entwicklung nachhaltiger Technologien.</p>
    
      <p><strong>Unterhaltung und Medien</strong> - Nutzung informatischer Technologien in der Unterhaltungsindustrie, einschließlich Videospielen, digitalen Medien, virtueller Realität (VR) und augmentierter Realität (AR).</p>
    
      <p><strong>Automobilindustrie und autonome Fahrzeuge</strong> - Einsatz von Informatik zur Entwicklung intelligenter Fahrzeugtechnologien, autonomer Fahrzeuge und zur Verbesserung der Fahrzeugsicherheit und Effizienz.</p>
    
      <p><strong>E-Commerce und digitales Marketing</strong> - Anwendung der Informatik zur Optimierung des Online-Handels, einschließlich personalisierter Kundenerfahrungen, Datenanalyse für Markttrends und Entwicklung sicherer Zahlungssysteme</p>
    
      <p><strong>Agrartechnologie</strong> - Einsatz informatischer Technologien in der Landwirtschaft zur Steigerung der Effizienz und Produktivität, einschließlich Präzisionslandwirtschaft, Drohnentechnologie und Datenanalyse für Ernteoptimierung.</p>
   
      <p>Diese Anwendungsbereiche zeigen, wie die Informatik dazu beiträgt, Innovationen voranzutreiben, Effizienz zu steigern und Lösungen für globale Herausforderungen zu entwickeln. Die Grenzen zwischen traditionellen Disziplinen verschwimmen zunehmend, da die Informatik in alle Bereiche des menschlichen Wissens und Handelns integriert wird.</p>
    </div>

     
    <div class="btn-container">
      <div class="custom-btn" @click="finishAboutTU()">Weiter</div>
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarIntroduction from "@/components/inf/introduction/AssignmentbarIntroduction.vue";
import * as scrollHelper from "@/helpers/scroll-helper.js";

export default {
  name: "InformatikIntroduction",
  components: {
    AssignmentbarIntroduction,
  },
  emits: ["about-tu-finished", "about-tu-back"],
  mounted() {
    scrollHelper.scrollToTop();
    this.dataReady = true;
  },
  data() {
    return {
      dataReady: false,
    };
  },
  methods: {
    async finishAboutTU() {
      this.$emit("about-tu-finished");
    },
    backBtnClicked() {
      this.$emit("about-tu-back");
    },
  },
};
</script>

<style scoped>
.yt-video {
  display: flex;
  justify-content: center;
  animation-delay: 0.8s;
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.delay-1 {
  animation-delay: 0.2s;
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
