<template>
  <div v-if="dataReady">
    <AssignmentbarIntroduction
      @end-assignment="alert('endAssignment')"
    ></AssignmentbarIntroduction>
    <div class="row animate glow delay-1">
      <h1>Herzlichen Glückwunsch!</h1>
      <p>Sie haben das Modul „Über Informatik und Informatikstudium an der TU Wien“ abgeschlossen. 
        Jetzt haben Sie einen Überblick über Informatik und ein Informatikstudium an der TU Wien. 
        Sie können mit den Experimentierbeispielen im OSA Informatik weitermachen, um tiefer in die Welt der Informatik einzudringen. 
        Viel Vergnügen!
</p>
    </div>
 
    <div class="animate glow user-study-comment">
      Dieses Modul befindet sich in Entwicklung und die derzeit enthaltenen
      Informationen stellen eine mögliche Vorstellung des Informatikstudiums der
      TU Wien dar. Im fertigen Modul „Über Informatik“ soll, neben Informationen
      zum Informatikstudium an der TU Wien, der Themenbereich Informatik einfach
      verständlich dargestellt werden. Neben textuellen Informationen werden
      manche Inhalte dieses Moduls mit kurzen interaktiven Beispielen und
      Erklärungen ergänzt. Dabei werden Teilnehmer:innen aktiv Inhalte der
      Informatik bearbeiten und es werden einfache Beispiele und Aufgaben
      spielerisch vorgestellt. Dadurch soll das Verständnis erhöht werden,
      welche Themen im Informatikstudium auf Studierende zukommen und womit sie
      sich im Studium auseinandersetzen müssen.
    </div>

    <div class="animate glow user-study-comment">
      <strong>Was möchten wir von Ihnen in der User Study wissen?</strong><br />
      Im Rahmen der User Study haben wir einen kurzen Fragebogen für dieses
      Modul vorbereitet. Dort können Sie Ihre Vorschläge und Anregungen
      einbringen, welche interaktiven Inhalte Ihrer Meinung nach in diesem Modul
      vorkommen sollen.
      <strong
        >Bitte öffnen und beantworten Sie den Fragebogen, bevor Sie mit anderen
        Teilen des OSA fortfahren.
      </strong>

      <div class="" style="display: flex; justify-content: center" v-if="false">
        <a
          href="https://survey.media.tuwien.ac.at/index.php/333469?lang=de "
          target="_blank"
          ><div class="custom-btn" @click="questionnaireOpened = true">
            Zum Fragebogen
          </div></a
        >
      </div>
    </div>
    <div class="btn-container">
      <div class="custom-btn" @click="finishConclusion()">
        Modul abschließen
      </div>
      <div class="custom-btn-secondary" @click="backBtnClicked()">Zurück</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarIntroduction from "@/components/inf/introduction/AssignmentbarIntroduction.vue";
import * as scrollHelper from "@/helpers/scroll-helper.js";

export default {
  name: "Conclusion",

  components: {
    AssignmentbarIntroduction,
  },
  emits: ["conclusion-finished", "conclusion-back"],
  mounted() {
    scrollHelper.scrollToTop();
    this.dataReady = true;
  },
  data() {
    return {
      dataReady: false,
    };
  },
  methods: {
    async finishConclusion() {
      this.$emit("conclusion-finished");
    },
    backBtnClicked() {
      this.$emit("conclusion-back");
    },
  },
};
</script>

<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.delay-1 {
  animation-delay: 0.2s;
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
