<template>
  <div class="container" v-if="dataReady">
    <div class="introduction-container" v-if="showIntroduction">
      <InformatikIntroduction @introduction-finished="startAboutTU()"></InformatikIntroduction>
    </div>
    <div class="about-tu-container" v-if="showAboutTU">
      <InformatikTUWien 
      @about-tu-finished="startAboutTU_2()"
      @about-tu-back="startIntroduction()">
    </InformatikTUWien>
  </div>

  <div class="about-tu-container" v-if="showAboutTU_2">
      <InformatikTUWien_2
      @about-tu-finished="startAboutTU_3()"
      @about-tu-back="startAboutTU()">
      </InformatikTUWien_2>
      </div>

 <div class="about-tu-container" v-if="showAboutTU_3">
      <InformatikTUWien_3
      @about-tu-finished="startAboutTU_4()"
      @about-tu-back="startAboutTU_2()">
      </InformatikTUWien_3>
      </div>

      <div class="about-tu-container" v-if="showAboutTU_4">
      <InformatikTUWien_4
      @about-tu-finished="startConclusion()"
      @about-tu-back="startAboutTU_3()">
      </InformatikTUWien_4>
      </div>

    
    <div class="study-activities-container" v-if="showStudyActivities">
      <StudyActivities></StudyActivities>
    </div>
    <div class="informatics-future-container" v-if="showInformaticsFuture">
      <InformaticsFuture></InformaticsFuture>
    </div>
    <div class="after-study-container" v-if="showAfterStudy">
      <AfterStudy></AfterStudy>
    </div>
    <div class="conclusion-container" v-if="showConclusion">
      <Conclusion @conclusion-finished="finishIntroduction()" @conclusion-back="startAboutTU_4()"></Conclusion>
    </div>
  </div>
</template>

<script>
import InformatikIntroduction from "@/components/inf/introduction/InformatikIntroduction.vue";
import InformatikTUWien from "@/components/inf/introduction/InformatikTUWien.vue";
import InformatikTUWien_2 from "@/components/inf/introduction/InformatikTUWien_2.vue";
import InformatikTUWien_3 from "@/components/inf/introduction/InformatikTUWien_3.vue";
import InformatikTUWien_4 from "@/components/inf/introduction/InformatikTUWien_4.vue";
import StudyActivities from "@/components/inf/introduction/StudyActivities.vue";
import InformaticsFuture from "@/components/inf/introduction/InformaticsFuture.vue";
import AfterStudy from "@/components/inf/introduction/AfterStudy.vue";
import Conclusion from "@/components/inf/introduction/Conclusion.vue";

import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js";

export default {
  name: "InformatikIntroductionView",
  components: {
    InformatikIntroduction,
    InformatikTUWien,
    InformatikTUWien_2,
    InformatikTUWien_3,
    InformatikTUWien_4,
    StudyActivities,
    InformaticsFuture,
    AfterStudy,
    Conclusion,
  },
  emits: [],
  data() {
    return {
      dataReady: false,
      user: {},
      showIntroduction: true,
      showAboutTU: false,
      showAboutTU_2: false,
      showAboutTU_3: false,
      showAboutTU_4: false,
      showStudyActivities: false,
      showInformaticsFuture: false,
      showAfterStudy: false,
      showConclusion: false,
    };
  },
  mounted() {
    this.user = JSON.parse(window.localStorage.getItem("userData"));
    this.$store.dispatch('setHeaderPageTitle', 'Online Self Assessment Informatik');
    this.$store.dispatch("setIsUserInAssignment", true); //user opened sortingView --> user is in assignment
    this.dataReady = true;
  },
  unmounted: function () {
    this.$store.dispatch("setIsUserInAssignment", false); //user closed sortingView --> user is not in assignment anymore
  },
  methods: {
    hideAllComponents(){
      this.showIntroduction = false;
      this.showAboutTU = false;
      this.showAboutTU_2 = false;
      this.showAboutTU_3 = false;
      this.showAboutTU_4 = false;
      this.showStudyActivities = false;
      this.showInformaticsFuture = false;
      this.showAfterStudy = false;
      this.showConclusion = false;
    },
    startIntroduction(){
      this.hideAllComponents();
      this.showIntroduction = true;
    },
    startAboutTU(){
      this.hideAllComponents();
      this.showAboutTU = true;
    },
    startAboutTU_2(){
      this.hideAllComponents();
      this.showAboutTU_2 = true;
    },
    startAboutTU_3(){
      this.hideAllComponents();
      this.showAboutTU_3 = true;
    },
    startAboutTU_4(){
      this.hideAllComponents();
      this.showAboutTU_4 = true;
    },
    startStudyActivities(){
      this.hideAllComponents();
      this.showStudyActivities = true;
    },
    startInformaticsFuture(){
      this.hideAllComponents();
      this.showInformaticsFuture = true;
    },
    startAfterStudy(){
      this.hideAllComponents();
      this.showAfterStudy = true;
    },
    startConclusion(){
      this.hideAllComponents();
      this.showConclusion = true;
    },
    async finishIntroduction(){
      if(this.user){
      // get my informatik OSA for current user 
      const myOSA = await myOSAhandler.getMyInformatikOSA(this.$store.getters.getUser.username);
      //set sortingFinished=true in backend
      await myOSAhandler.finishModule(myOSA.id, { introFinished: true })

      }
      this.$router.push("/informatik");
    }
  },
};
</script>

<style></style>
