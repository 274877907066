<template>
  <div class="container" v-if="dataReady">
    <AssignmentbarIntroduction
      @end-assignment="alert('endAssignment')"
    ></AssignmentbarIntroduction>
    <div class="row animate glow delay-1" style="line-height: 1;">
      <h1>Über Informatik</h1>
     
      <p>
        In der Informatik kann man neben den klassischen Kategorien wie theoretischer, praktischer, technischer und angewandter Informatik weitere wichtige Forschungsbereiche identifizieren, die sich auf spezifische Anwendungen und Technologien konzentrieren. Zu diesen Bereichen gehören:

      </p>

      <p>
        <strong>Künstliche Intelligenz (KI)</strong> - Fokussiert auf die Schaffung von Computersystemen, die Aufgaben erfüllen können, die normalerweise menschliche Intelligenz erfordern, wie Lernen, Urteilsvermögen und Problemlösung.

      </p>
      <p>
        <strong>Datenwissenschaft und Big Data</strong> - Beschäftigt sich mit der Analyse, Verarbeitung und Darstellung großer und komplexer Datenmengen zur Gewinnung von Erkenntnissen und Informationen.</p>

      <p> <strong>Cybersicherheit</strong> - Konzentriert sich auf den Schutz von Computersystemen, Netzwerken und Daten vor digitalen Angriffen, Diebstahl und Schäden.
</p>
      
      <p> <strong>Mensch-Computer-Interaktion (HCI)</strong> - Untersucht, wie Menschen mit Computern interagieren, und entwickelt benutzerfreundliche Schnittstellen, um diese Interaktionen zu verbessern.
</p>
      <p> <strong>Netzwerke und Internet-Technologien</strong> - Betrifft die Entwicklung und Verwaltung von Netzwerkarchitekturen, Protokollen sowie die Analyse und Optimierung von Internetverbindungen und -diensten.</p>
      <p> <strong>Software-Engineering</strong> - Bezieht sich auf die systematische Herangehensweise an die Entwicklung, den Betrieb und die Wartung von Software, einschließlich der Methoden und Werkzeuge, 
        die zur Unterstützung dieser Prozesse verwendet werden.</p>
      <p><strong>Computersysteme und Architektur</strong> - Beinhaltet das Studium von Computerhardware, Systemdesign und der Optimierung von Computerarchitekturen für verschiedene Anwendungen.
</p>
      <p><strong>Robotik</strong> - Entwickelt Maschinen, die automatisierte Aufgaben ausführen können, oft mit dem Ziel, menschliche Arbeit zu ergänzen oder zu ersetzen.</p>
      <p>Diese Kategorien repräsentieren nur einen Teil der vielfältigen und sich ständig weiterentwickelnden Landschaft der Informatikforschung. Jeder dieser Bereiche trägt auf seine Weise zur Weiterentwicklung der Technologie und zur Lösung komplexer Probleme in der Gesellschaft bei.</p>

    </div>

    <div class="btn-container">
      <div class="custom-btn" @click="finishAboutTU()">Weiter</div>
      <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
    </div>
  </div>
</template>

<script>
import AssignmentbarIntroduction from "@/components/inf/introduction/AssignmentbarIntroduction.vue";
import * as scrollHelper from "@/helpers/scroll-helper.js";

export default {
  name: "InformatikIntroduction",
  components: {
    AssignmentbarIntroduction,
  },
  emits: ["about-tu-finished", "about-tu-back"],
  mounted() {
    scrollHelper.scrollToTop();
    this.dataReady = true;
  },
  data() {
    return {
      dataReady: false,
    };
  },
  methods: {
    async finishAboutTU() {
      this.$emit("about-tu-finished");
    },
    backBtnClicked() {
      this.$emit("about-tu-back");
    },
  },
};
</script>

<style scoped>
.yt-video {
  display: flex;
  justify-content: center;
  animation-delay: 0.8s;
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.delay-1 {
  animation-delay: 0.2s;
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
